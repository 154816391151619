import { useState } from "react";
import {
  ScissorOutlined,
  CopyOutlined,
  MoreOutlined,
  FileTextOutlined,
  CloseOutlined,
  CheckOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  MenuProps,
  Dropdown,
  Modal,
  AutoComplete,
  Descriptions,
  Alert,
  Space,
  notification,
  Button,
  Input,
} from "antd";
import { Part } from "../../models/Part";
import { Project, ProjectStatus } from "../../models/Project";
import projectServices from "../../services/project.service";
import partServices from "../../services/part.service";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../redux/slides/project.slide";
import { ActionMode } from "../../models/PartActionMode";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import CadFileItem from "./CadFileItem";
import { PartViewMode } from "../../models/PartViewMode";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./cadFileItem.module.scss";

interface DefaultProps {
  project: Project;
  part: Part;
  selfCalculation?: boolean;
  onSubmitted?: any;
  hiddenDeleteBtn?: boolean;
  hiddenViewBtn?: boolean;
  hiddenMoveBtn?: boolean;
  onDeleteClick?: any;
}

function DropdownActions(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const {
    project,
    selfCalculation = false,
    onSubmitted,
    part,
    hiddenViewBtn = false,
    hiddenMoveBtn = false,
    hiddenDeleteBtn = true,
    onDeleteClick
  } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const { t } = useTranslation();
  const { accessCode } = useParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mode, setMode] = useState<ActionMode>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [options, setOptions] = useState<
    { value: string; label: string; project: Project }[]
  >([]);
  const [projectSelected, setProjectSelected] = useState<Project>();
  const [error, setError] = useState<any>();
  const [submiting, setSubmiting] = useState(false);
  const [isOpenDecline, setIsOpenDecline] = useState(false);
  const [toggleApproving, setToggleApproving] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const isOwner =
    (project.selfCalculation &&
      project.offerUser?.email === auth.user?.email) ||
    project.createdBy?.email === auth.user?.sub;

  const onMenuSelect = (mode: any) => {
    switch (mode) {
      case ActionMode.VIEW:
        if (onSubmitted) onSubmitted(ActionMode.VIEW, part);
        break;
      default:
        setMode(mode);
        setIsOpenModal(true);
        onSearch("");
        break;
    }
  };

  const onSearch = async (text: any) => {
    try {
      const rs = await projectServices.list({
        text,
        size: 10,
        page: 0,
        sortDir: "DESC",
        sortProperty: "id",
        statuses: ProjectStatus.IN_PROGRESS,
        selfCalculation: auth.user.role === UserRole.SELLER,
        block: false,
      });
      const data = rs.data.filter((p: any) => p.id !== project.id);
      setProjects(data);
      const dataOptions = data.map((p: any) => {
        return {
          value: p.name,
          label: p.name,
          project: p,
        };
      });
      setOptions(dataOptions);
    } catch (error) {}
  };

  const onSelect = (value: any, option: any) => {
    setProjectSelected(option.project);
  };

  const onSubmitAction = async () => {
    setSubmiting(true);
    setError(undefined);
    if (!projectSelected) {
      setError({
        message: "Please select a project!",
      });
    } else {
      try {
        let rs: any;
        let title = "";
        switch (mode) {
          case ActionMode.CLONE:
            rs = await partServices.copy({
              partIds: [part.id],
              projectId: projectSelected.id,
            });
            api.info({
              message: title,
              description: (
                <>
                  {part.partFileName} copied to "{projectSelected.name}"
                </>
              ),
              placement: "topRight",
            });
            break;
          case ActionMode.MOVE:
            rs = await partServices.move({
              partIds: [part.id],
              projectId: projectSelected.id,
            });
            api.info({
              message: title,
              description: (
                <>
                  {part.partFileName} moved to "{projectSelected.name}"
                </>
              ),
              placement: "topRight",
            });
            dispatch(projectActions.deletePart(part.id));
            break;
          default:
            break;
        }
        setIsOpenModal(false);
        if (onSubmitted) onSubmitted(mode);
      } catch (error: any) {
        setError({
          message: error.message,
        });
      }
    }
    setSubmiting(false);
  };

  const onCloseModal = () => {
    setError(null);
    setProjectSelected(undefined);
    setIsOpenModal(false);
  };

  const renderTitle = () => {
    switch (mode) {
      case ActionMode.MOVE:
        return t("move");
      case ActionMode.CLONE:
        return t("copy");
      default:
        return "";
    }
  };

  const onOpenDecline = () => {
    setIsOpenDecline(true);
  };
  const onHideDecline = () => {
    setIsOpenDecline(false);
  };
  const onApproveSubmit = async () => {
    setToggleApproving(true);
    try {
      let rs: any;
      if (!accessCode)
        rs = await partServices.toggleApprove({
          partId: part.id,
          approved: !part.approved,
        });
      else
        rs = await partServices.toggleApproveByAccessCode({
          partId: part.id,
          approved: !part.approved,
          accessCode,
        });
      dispatch(projectActions.setProject(rs));
      onHideDecline();
    } catch (error) {}
    setToggleApproving(false);
  };

  const onDeletePart = async () => {
    setDeleting(true);
    try {
      if (part) {
        await partServices.deletePart(part?.id);
        dispatch(projectActions.deletePart(part?.id));
      }
      setIsOpenDelete(false);
    } catch (error) {
    }
    setDeleting(false);
  };
  const showDeleteModal = (event: any) => {
    if (onDeleteClick) onDeleteClick();
  };
  const cancelDeleteModal = () => {
    setIsOpenDelete(false);
  };

  const actionsMenu: MenuProps["items"] = [
    ...(!hiddenDeleteBtn
      ? [
          {
            label: t("delete"),
            key: "delete",
            icon: <DeleteOutlined />,
            onClick: showDeleteModal,
          },
        ]
      : []),
    ...(project.status === ProjectStatus.PRICE_ADAPTED &&
    (auth.user?.role !== UserRole.SELLER || accessCode)
      ? [
          {
            label: part.approved ? t("decline") : t("approve"),
            key: "toggle",
            icon: part.approved ? <CloseOutlined /> : <CheckOutlined />,
            onClick: onOpenDecline,
          },
        ]
      : []),
    ...(hiddenViewBtn
      ? []
      : [
          {
            label: t("view"),
            key: "view",
            icon: <FileTextOutlined />,
            onClick: onMenuSelect.bind(null, ActionMode.VIEW),
          },
        ]),
    ...(!accessCode && isOwner
      ? [
          {
            label: t("copy"),
            key: "copy",
            icon: <CopyOutlined />,
            onClick: onMenuSelect.bind(null, ActionMode.CLONE),
          },
        ]
      : []),
    ...(!hiddenMoveBtn &&
    project.status === ProjectStatus.IN_PROGRESS &&
    !project.blocked
      ? [
          {
            label: t("move"),
            key: "move",
            icon: <ScissorOutlined />,
            onClick: onMenuSelect.bind(null, ActionMode.MOVE),
          },
        ]
      : []),
  ];
  const actionBtn: any = actionsMenu[0];
  const menus: any = actionsMenu;

  return (
    <>
      {contextHolder}
      <Space className="part-action" direction="horizontal">
        
        {menus.length === 0 && (
          <Button type="default" onClick={actionBtn.onClick}>
            {actionBtn.icon} {actionBtn.label}
          </Button>
        )}
        {menus.length > 0 && (
          <Dropdown
            menu={{ items: menus }}
            placement="bottomRight"
          >
            <Button><MoreOutlined /></Button>
          </Dropdown>
        )}
      </Space>

      <Modal
        width={"80vw"}
        open={isOpenModal}
        onCancel={onCloseModal}
        title={renderTitle()}
        onOk={onSubmitAction}
        okButtonProps={{
          loading: submiting,
        }}
        cancelButtonProps={{
          disabled: submiting,
        }}
        destroyOnClose
      >
        {error && (
          <Alert
            message={error.message}
            showIcon
            type="error"
            className="mb-3"
          />
        )}
        <CadFileItem
          className="no-border"
          part={part}
          mode={PartViewMode.READ_ONLY}
        />
        <AutoComplete
          style={{ width: "100%" }}
          onSearch={onSearch}
          options={options}
          onSelect={onSelect}
        >
          <Input
            prefix={<SearchOutlined />}
            placeholder={t("project.copy.search") || ""}
          />
        </AutoComplete>
        {projectSelected && (
          <>
            <h6 className="mt-3">{t("project.selected")}</h6>
            <Descriptions>
              <Descriptions.Item label="Name">
                {projectSelected.name}
              </Descriptions.Item>
              <Descriptions.Item label="Parts">
                {projectSelected.parts?.map((p) => p.partFileName).join(", ")}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Modal>
      <Modal
        open={isOpenDecline}
        title={part.approved ? t("popup.toggle.decline.title") : t("popup.toggle.approve.title")}
        onCancel={onHideDecline}
        onOk={onApproveSubmit}
        okButtonProps={{
          loading: toggleApproving,
        }}
        cancelButtonProps={{
          disabled: toggleApproving,
        }}
      >
        <p>{part.approved ? t("popup.toggle.decline.message") : t("popup.toggle.approve.message")}</p>
      </Modal>
      <Modal
        open={isOpenDelete}
        title={`${part?.name || part?.partFileName}`}
        onCancel={cancelDeleteModal}
        onOk={onDeletePart}
        cancelButtonProps={{
          disabled: deleting,
        }}
        okButtonProps={{
          loading: deleting,
        }}
      >
        {t("part.delete.title", {
          name: part?.name || part?.partFileName,
        })}
      </Modal>
    </>
  );
}

export default DropdownActions;
