import { Layout, theme } from "antd";
import { images } from "../../../assets/images";
import { Outlet, useNavigate } from "react-router-dom";
import MenuSide from "../../components/MenuSide";
import { useDispatch, useSelector } from "react-redux";
import { authActions, authSelector } from "../../redux/slides/auth.slide";

import { setAuthorization } from "../../services";
import LocaleButton from "../../components/Locale/LocaleButton";
import AddProjectButton from "../../components/Project/AddProjectButton";
import { useTranslation } from "react-i18next";
import SearchProjects from "../../components/Project/SearchProjects";
import styles from "./main.module.scss";
import { useState } from "react";
import { UserRole } from "../../models/UserRole";
const { Header, Content, Sider } = Layout;

export function MainPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [isOpenMenuSide, setIsOpenMenuSide] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onLogout = () => {
    setAuthorization(undefined);
    dispatch(authActions.clear());
    navigate("/");
  };

  const btnMenuSideClick = () => {
    setIsOpenMenuSide(!isOpenMenuSide);
  };

  const onCloseMenuSide = () => {
    setIsOpenMenuSide(false);
  };

  return (
    <Layout className={styles.main}>
      <Header className="header">
        <div className="w-100 d-flex align-items-center px-3">
          <div
            className={`nav-icon ${isOpenMenuSide && "open"}`}
            onClick={btnMenuSideClick}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src="/images/logo.png" className={styles.logo} />
        </div>
      </Header>
      <Layout>
        <Sider width={"unset"} className={` ${isOpenMenuSide && "show"}`}>
          <div className="d-flex flex-column pb-4 menu-side">
            <div className="m-3" onClick={onCloseMenuSide}>
              <img src="/images/logo.png" className={`${styles.logo} logo`} />
              {auth.user?.role === UserRole.BUYER && <div>
                <AddProjectButton />
              </div>}
              <SearchProjects />
            </div>
            <div style={{
              overflowY: "auto"
            }} onClick={onCloseMenuSide}>
              <MenuSide />
            </div>
            <div className="mt-auto row m-2 relative justify-content-between" onClick={onCloseMenuSide}>
              <div className="col-9" style={{lineBreak: "anywhere"}}>
                <label className="truncate" title={auth?.user?.sub}>{auth?.user?.sub}</label>
                <br />
                <a className="text-primary" onClick={onLogout}>
                  {t("logout")}
                </a>
              </div>
              <div className="col-auto">
                {/* <Avatar size={40} icon={<UserOutlined />} /> */}
                <LocaleButton />
              </div>
            </div>
          </div>
        </Sider>
        <Layout
          style={
            {
              // padding: "24px",
              // paddingLeft: "calc(15vw + 24px)",
              // paddingBottom: 80,
            }
          }
        >
          <Content
          // style={{
          //   padding: 24,
          //   margin: 0,
          //   minHeight: 280,
          //   background: colorBgContainer,
          // }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
