import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
import { Form, FormInstance, Input } from "antd";
import ENIcon from "../SVGs/ENIcon";
import DEIcon from "../SVGs/DEIcon";
import { useEffect } from "react";
import { configSelector } from "app/redux/slides/config.slide";
import ITIcon from "../SVGs/ITIcon";

interface DefaultProps {
  form: FormInstance<any>;
  data: any;
}
const LocalizationFormItem = (props: DefaultProps) => {
  const { form, data } = props;
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const locales = config.locales || [];
  useEffect(() => {
    if (form) {
      setTimeout(() => {
        locales.map((key: any, index: any) => {    
          form.setFieldValue(
            ["localizations", index, "locale"],
            key.toUpperCase()
          );
        });
        // form.setFieldValue(["localizations", 0, "locale"], "EN");
        // form.setFieldValue(["localizations", 1, "locale"], "DE");
        // form.setFieldValue(["localizations", 2, "locale"], "IT");
      }, 500);
    }
  }, [data]);

  const flagIcon = (key: any) => {
    switch (key) {
      case "en":
        return <ENIcon width={"1vw"} height={"1vw"} />;
      case "de":
        return <DEIcon width={"1vw"} height={"1vw"} />;
      case "it":
        return <ITIcon width={"1vw"} height={"1vw"} />;
      default:
        break;
    }
  };
  return (
    <>
      <p className="text-label mb-2">{t("localizedNames")}</p>
      {locales.map((key: any, index: any) => (
        <div key={`localizations-${key}`}>
          <Form.Item name={["localizations", index, "id"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["localizations", index, "locale"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            // label={t("localizedNames")}
            name={["localizations", index, "name"]}
            rules={[{ required: true }]}
            messageVariables={{
              label: `${key.toUpperCase()} ${t("name")}`,
            }}
          >
            <Input prefix={flagIcon(key)} />
          </Form.Item>
        </div>
      ))}
      {/* <Form.Item name={["localizations", 1, "id"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["localizations", 1, "locale"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name={["localizations", 1, "name"]}
        rules={[{ required: true }]}
        messageVariables={{
          label: "DE Name",
        }}
      >
        <Input prefix={<DEIcon width={"1vw"} height={"1vw"} />} />
      </Form.Item> */}
    </>
  );
};

export default LocalizationFormItem;
