/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  DeleteOutlined,
  DownCircleOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined,
  LinkOutlined,
  MoreOutlined,
  SearchOutlined,
  UpCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Dropdown,
  Empty,
  Form,
  Input,
  MenuProps,
  Modal,
  Popover,
  Select,
  Space,
  notification,
} from "antd";
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TableData, TableFilter, TableParams } from "../../models/Table";
import { loadingActions } from "../../redux/slides/loading.slide";
import projectServices from "../../services/project.service";
import { Project, ProjectStatus } from "../../models/Project";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import "./project.module.scss";
import { Part } from "../../models/Part";
import CurrencyFormat from "../Format/CurrencyFormat";
import { authSelector } from "../../redux/slides/auth.slide";
import moment from "moment";
import { Order } from "../../models/Order";
import { UserRole } from "../../models/UserRole";
import { profileSelector } from "../../redux/slides/user.slide";
import ProjectStatusTag from "./ProjectStatusTag";
import { useTranslation } from "react-i18next";
import PartImage from "../Part/PartImage";
import DxfFileIcon from "../SVGs/DxfFileIcon";
import { Archive, Trash, Trash2, Trash3 } from "react-bootstrap-icons";
import LocalizationName from "../Locale/LocalizationName";
import NumberFormat from "../Format/NumberFormat";

type DefaultProps = {
  statuses: ProjectStatus[];
  createById?: number;
  isSelf?: boolean;
  isManual?: boolean;
  isSearchMode?: boolean;
  isArchived?: boolean;
};

function ListProjects(props: DefaultProps) {
  const {
    statuses,
    isSelf = false,
    isSearchMode = false,
    createById,
    isArchived = false,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const user = useSelector(profileSelector);
  const [data, setData] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [isOpenDeleteProject, setIsOpenDeleteProject] = useState(false);
  const [projectId, setProjectId] = useState<any>(undefined);
  const [modal, modalContextHolder] = Modal.useModal();

  const [tableParams, setTableParams] = useState<TableParams>({
    order: "descend",
    field: "id",
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [filters, setFilters] = useState<TableFilter>({
    statuses: statuses.join(","),
    text: "",
  });

  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const generateProjectViewUrl = (project: Project) => {
    return project.status === ProjectStatus.IN_PROGRESS
      ? `/${auth.user.role}/projects/${project.id}/calculations`
      : `/${auth.user.role}/projects/${project.id}`;
  };

  const handleViewProject = (project: Project) => {
    const url = generateProjectViewUrl(project);
    if (isSearchMode) {
      window.open(url, "_blank");
      return;
    }

    navigate(url);
  };

  const handleArchiveProject = async (projectId: any) => {
    try {
      const rs: Project = await projectServices.updateStatusArchive(projectId);
      api.success({
        message: t("archived"),
        description: t("archivedNotification", {name: rs.name}),
        placement: "topRight",
      });

      getProjects();
    } catch (error) {}
  };

  const handleUnArchiveProject = async (projectId: any) => {
    try {
      const rs: Project = await projectServices.updateStatusUnArchive(
        projectId
      );
      api.success({
        message: t("unarchived"),
        description: t("unarchivedNotification", {name: rs.name}),
        placement: "topRight",
      });

      getProjects();
    } catch (error) {
      console.log(error);
    }
  };

  const performDeleteAction = async (projectId: any) => {
    try {
      setLoading(true);
      const rs: Project = await projectServices.deleteProject(projectId);
      api.success({
        message: t("deleted"),
        description: t("deletedNotification"),
        placement: "topRight",
      });
      getProjects();
      setProjectId(undefined);
    } catch (error) {
      api.error({
        message: `Error!`,
        description: `Delete Failed!`,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleDeleteProject = async (projectId: any) => {
    setProjectId(projectId);
    // // setIsOpenDeleteProject(true);
    // modal.confirm({
    //   title: t("popup.deleteOffer.title"),
    //   icon: null,
    //   content: t("popup.deleteOffer.message"),
    //   // okText: "OK",
    //   // cancelText: "Close",
    //   onOk: () => performDeleteAction(projectId),
    // });
  };

  const actionBtn: any = {
    label: t("view"),
    key: "view",
    icon: <FolderOpenOutlined />,
    onClick: (url: any) => handleViewProject.bind(null, url),
  };

  const RenderProjectAction = (record: Project) => {
    let columnActionBtn: MenuProps["items"] = [];
    if (record.archived === false) {
      columnActionBtn = [
        {
          label: t("archive"),
          key: "archive",
          icon: <Archive />,
          onClick: () => handleArchiveProject(record.id),
        },
      ];
    }

    if (record.archived === true) {
      columnActionBtn = [
        {
          label: t("unarchive"),
          key: "unarchive",
          icon: <Archive style={{ fontWeight: 800, fontSize: 17 }} />,
          onClick: () => handleUnArchiveProject(record.id),
        },
        {
          label: t("delete"),
          key: "delete",
          icon: <DeleteOutlined style={{ fontWeight: 800, fontSize: 17 }} />,
          onClick: () => handleDeleteProject(record.id),
        },
      ];
    }

    if (
      record.status === ProjectStatus.IN_PROGRESS ||
      record.archived === true
    ) {
      return (
        <Dropdown.Button
          icon={<MoreOutlined />}
          menu={{ items: columnActionBtn }}
          placement="bottomRight"
          onClick={actionBtn.onClick(record)}
        >
          {actionBtn.icon} {actionBtn.label}
        </Dropdown.Button>
      );
    }

    return (
      <Link
        to={generateProjectViewUrl(record)}
        target={isSearchMode ? "_blank" : undefined}
      >
        <FolderOpenOutlined />
      </Link>
    );
  };

  const getProjects = async () => {
    // dispatch(loadingActions.show(true));
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order !== "descend" ? "ASC" : "DESC",
        sortProperty:
          tableParams.columnKey?.toString() || tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
        selfCalculation: isSelf || undefined,
        ...filters,
        ...(isSelf
          ? { createById: user.id }
          : createById
          ? { createById }
          : {}),
        ...(isSearchMode
          ? {
              statuses:
                auth.user?.role === UserRole.BUYER
                  ? filters.statuses?.join(",") || ""
                  : filters.statuses?.join(",") ||
                    [
                      ProjectStatus.CHECKOUTED,
                      ProjectStatus.REQUESTED_OFFER,
                      ProjectStatus.PRICE_ADAPTED,
                      ProjectStatus.BUYER_APPROVED,
                      ProjectStatus.BUYER_REJECTED,
                      ProjectStatus.SELLER_APPROVED,
                      ProjectStatus.SELLER_REJECTED,
                    ].join(","),
            }
          : { statuses: statuses.join(",") }),
        read: filters.unread === true ? false : undefined,
        unread: undefined,
        isArchived: isArchived,
      };

      const rs: TableData = await projectServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
        },
      });
      setPagination({
        ...tableParams.pagination,
        total: rs.totalItems,
      });
    } catch (error) {}
    dispatch(loadingActions.show(false));
    setLoading(false);
  };

  useEffect(() => {
    if (!isSearchMode) {
      getProjects();
    } else if (
      filters.text ||
      filters.deliveryDateFrom ||
      filters.deliveryDateTo ||
      filters.statuses ||
      filters.unread ||
      filters.orderDateFrom ||
      filters.orderDateTo
    ) {
      getProjects();
    } else {
      setData([]);
    }
  }, [JSON.stringify(tableParams), JSON.stringify(filters)]);
  useEffect(() => {
    if (projectId) {
      setIsOpenDeleteProject(true);
    } else {
      setIsOpenDeleteProject(false);
    }
  }, [projectId]);

  const columns: ColumnsType<Project> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("project.table.name"),
      dataIndex: "name",
      sorter: true,
      width: "16vw",
      render: (data, record) => (
        <>
          <Space direction="vertical">
            <Link
              // to={`/${auth.user.role}/projects/${record.id}`}
              to={
                record.status === ProjectStatus.IN_PROGRESS
                  ? `/${auth.user.role}/projects/${record.id}/calculations`
                  : `/${auth.user.role}/projects/${record.id}`
              }
              className="text-decoration-none"
            >
              {data}
            </Link>
            {/* <ProjectStatusTag status={record.status} /> */}
          </Space>{" "}
          {record.linkedProject &&
            (auth.user.role !== UserRole.SELLER ||
              record.linkedProject.status !== ProjectStatus.IN_PROGRESS) && (
              <>
                <Popover
                  placement="right"
                  trigger="hover"
                  content={
                    <>
                      <a
                        className="text-white text-decoration-none"
                        href={`/${auth.user.role}/projects/${record.linkedProject.id}`}
                        target="_blank"
                      >
                        <FolderOpenOutlined /> {record.linkedProject?.name}
                      </a>
                      <ProjectStatusTag
                        status={record.linkedProject.status}
                        className="ms-2"
                      />
                    </>
                  }
                >
                  <LinkOutlined className="pointer" />
                </Popover>{" "}
              </>
            )}
        </>
      ),
    },
    Table.EXPAND_COLUMN,
    {
      title: "",
      dataIndex: "parts",
      align: "left",
      render: (data) => (
        <Space className="" direction="horizontal" size={8}>
          <Avatar.Group
            className="part-group"
            maxCount={8}
            size="large"
            maxStyle={{ color: "#222222", backgroundColor: "#ffc107" }}
          >
            {data?.map((item: Part) => (
              <Avatar
                key={`part-${item.id}`}
                src={item.preview2dUrl || item.previewPngUrl}
                style={{ background: "#f1f1f1" }}
              >
                {!item.preview2dUrl && item.partFileName && <DxfFileIcon />}
              </Avatar>
            ))}
          </Avatar.Group>
        </Space>
      ),
    },
    ...(statuses[0] !== ProjectStatus.IN_PROGRESS
      ? [
          {
            title: t("project.table.deliveryDate"),
            dataIndex: "order",
            sorter: true,
            key: "order_deliveryDate",
            render: (data: Order, record: Project) => (
              <div className="d-flex">
                {data.adaptedDeliveryDate
                  ? moment(data.adaptedDeliveryDate).format("L")
                  : data.deliveryDate
                  ? moment(data.deliveryDate).format("L")
                  : "--"}
                &nbsp;
                {!record.manual &&
                  !record.selfCalculation &&
                  record.adapted &&
                  record.status !== ProjectStatus.CHECKOUTED &&
                  record.order.adaptedDeliveryDate && (
                    <Popover
                      overlayStyle={{
                        maxWidth: "20vw",
                      }}
                      placement="right"
                      content={
                        <>
                          {t("project.adapted")}
                          {t("project.adapted.deliveryDate", {
                            deliveryDate: moment(
                              record.order.deliveryDate
                            ).format("LL"),
                            adaptedDeliveryDate: moment(
                              record.order.adaptedDeliveryDate
                            ).format("LL"),
                          })}
                        </>
                      }
                    >
                      <WarningOutlined className="text-danger" />
                    </Popover>
                  )}
              </div>
            ),
          },
          {
            title: t("project.table.orderNumber"),
            dataIndex: "order",
            sorter: true,
            key: "order_orderNumber",
            render: (data: Order) => data.orderNumber || "--",
          },
          {
            title: t("project.table.total"),
            dataIndex: "order",
            className: "text-center",
            sorter: true,
            key: "order_price_netTotal",
            width: "140px",
            render: (data: Order, record: Project) => (
              <div className="d-flex justify-content-end fw-semibold">
                <CurrencyFormat
                  value={data.price?.total}
                  hiddenValue={record.status === ProjectStatus.REQUESTED_OFFER}
                />
                &nbsp;
                {!record.manual &&
                  !record.selfCalculation &&
                  record.adapted &&
                  record.status !== ProjectStatus.CHECKOUTED &&
                  record.parts?.some((p) => p.adaptedUnitPrice) && (
                    <Popover
                      overlayStyle={{
                        maxWidth: "20vw",
                      }}
                      placement="right"
                      content={
                        <>
                          {t("project.adapted")}
                          {t("project.adapted.price")}
                        </>
                      }
                    >
                      <WarningOutlined className="text-danger" />
                    </Popover>
                  )}
              </div>
            ),
          },
        ]
      : []),
    {
      title: t("project.table.status"),
      dataIndex: "status",
      width: "10vw",
      sorter: true,
      render: (data) => <ProjectStatusTag status={data} />,
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      width: "10vw",
      sorter: true,
      render: (data) =>
        data ? moment(data).format("L") + " " + moment(data).format("LT") : "",
    },
    {
      title: t("action"),
      key: "operation",
      // fixed: "right",
      width: 100,
      render: (_: any, record: Project) => RenderProjectAction(record),
    },
  ];
  const sellerColumns: ColumnsType<Project> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("project.table.name"),
      dataIndex: "name",
      sorter: true,
      width: "14vw",
      render: (data, record) => (
        <>
          <Space direction="vertical">
            <Link
              // to={`/${auth.user.role}/projects/${record.id}`}
              to={
                record.status === ProjectStatus.IN_PROGRESS
                  ? `/${auth.user.role}/projects/${record.id}/calculations`
                  : `/${auth.user.role}/projects/${record.id}`
              }
              className="text-decoration-none"
            >
              {data}
            </Link>
            {/* <ProjectStatusTag status={record.status} /> */}
          </Space>{" "}
          {record.linkedProject &&
            (auth.user.role !== UserRole.SELLER ||
              record.linkedProject.status !== ProjectStatus.IN_PROGRESS) && (
              <>
                <Popover
                  placement="right"
                  trigger="hover"
                  content={
                    <>
                      <a
                        className="text-white text-decoration-none"
                        href={`/${auth.user.role}/projects/${record.linkedProject.id}`}
                        target="_blank"
                      >
                        <FolderOpenOutlined /> {record.linkedProject?.name}
                      </a>
                      <ProjectStatusTag
                        status={record.linkedProject.status}
                        className="ms-2"
                      />
                    </>
                  }
                >
                  <LinkOutlined className="pointer" />
                </Popover>{" "}
              </>
            )}
        </>
      ),
    },
    ...(statuses[0] !== ProjectStatus.IN_PROGRESS
      ? [
          {
            title: t("companyName"),
            dataIndex: "order",
            sorter: true,
            key: "createdBy_companyName",
            render: (data: Order) => data.createdBy?.companyName || "--",
          },
          {
            title: t("project.table.orderNumber"),
            dataIndex: "order",
            sorter: true,
            key: "order.orderNumber",
            render: (data: Order) => data.orderNumber || "--",
          },
          {
            title: t("project.table.orderDate"),
            dataIndex: "order",
            sorter: true,
            key: "order_createdAt",
            render: (data: Order) => (
              <>{data.createdAt ? moment(data.createdAt).format("L") : ""}</>
            ),
          },
          {
            title: t("price.netTotal"),
            dataIndex: "order",
            className: "text-center",
            sorter: true,
            key: "order_price_netTotal",
            width: "140px",
            render: (data: Order, record: Project) => (
              <div className="text-end fw-semibold">
                <CurrencyFormat
                  value={data.price?.netTotal}
                  // hiddenValue={record.status === ProjectStatus.REQUESTED_OFFER}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      title: t("project.table.status"),
      dataIndex: "status",
      width: "10vw",
      sorter: true,
      render: (data) => <ProjectStatusTag status={data} />,
    },
    {
      title: t("action"),
      key: "operation",
      // fixed: "right",
      width: 100,
      render: (_: any, record: Project) => RenderProjectAction(record),
    },
  ];
  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Project>
  ) => {
    setTableParams({
      ...tableParams,
      pagination,
      filters,
      ...sorter,
    });
  };

  const onFilters = (values: any) => {
    // use deliveryDate for byer and orderDate for seller
    let dateFromKey = "orderDateFrom";
    let dateToKey = "orderDateTo";

    if (auth.user?.role === UserRole.BUYER) {
      dateFromKey = "deliveryDateFrom";
      dateToKey = "deliveryDateTo";
    }

    if (values.dateRange) {
      let dateFilter: any = {};
      dateFilter[dateFromKey] = values.dateRange[0]?.format("YYYY-MM-DD");
      dateFilter[dateToKey] = values.dateRange[1]?.format("YYYY-MM-DD");

      values = {
        ...values,
        ...dateFilter,
      };
    }
    delete values.dateRange;
    setFilters({
      ...values,
    });
  };

  return (
    <div>
      {contextHolder}
      {modalContextHolder}
      {isSearchMode && (
        <Form onFinish={onFilters} className="app-form">
          <div className="row align-item-start">
            <Form.Item name="text" className="col col-12 col-md-4">
              <Input
                style={{ width: "100%" }}
                placeholder={t("project.input.placeholder.search") || ""}
                allowClear
              />
            </Form.Item>
            <Form.Item name="statuses" className="col col-12 col-md-3">
              <Select
                style={{
                  width: "100%",
                }}
                placeholder={t("project.status") || ""}
                mode="tags"
                allowClear
              >
                {auth.user.role === UserRole.BUYER && (
                  <Select.Option key={`${ProjectStatus.IN_PROGRESS}`}>
                    {t(
                      `project.status.${auth.user.role}.${ProjectStatus.IN_PROGRESS}`
                    )}
                  </Select.Option>
                )}
                <Select.Option key={`${ProjectStatus.REQUESTED_OFFER}`}>
                  {t(
                    `project.status.${auth.user.role}.${ProjectStatus.REQUESTED_OFFER}`
                  )}
                </Select.Option>
                <Select.Option key={`${ProjectStatus.CHECKOUTED}`}>
                  {t(
                    `project.status.${auth.user.role}.${ProjectStatus.CHECKOUTED}`
                  )}
                </Select.Option>
                <Select.Option key={`${ProjectStatus.PRICE_ADAPTED}`}>
                  {t(
                    `project.status.${auth.user.role}.${ProjectStatus.PRICE_ADAPTED}`
                  )}
                </Select.Option>
                <Select.Option
                  key={`${ProjectStatus.BUYER_APPROVED},${ProjectStatus.SELLER_APPROVED}`}
                >
                  {t(
                    `project.status.${auth.user.role}.${ProjectStatus.BUYER_APPROVED}`
                  )}
                </Select.Option>
                <Select.Option
                  key={`${ProjectStatus.BUYER_REJECTED},${ProjectStatus.SELLER_REJECTED}`}
                >
                  {t(
                    `project.status.${auth.user.role}.${ProjectStatus.BUYER_REJECTED}`
                  )}
                </Select.Option>
                {/* {Object.values(ProjectStatus).map(
                  (item) =>
                    !(
                      auth.user.role === UserRole.SELLER &&
                      item === ProjectStatus.IN_PROGRESS
                    ) && (
                      <Select.Option key={`${item}`}>
                        {t(`project.status.${auth.user.role}.${item}`)}
                      </Select.Option>
                    )
                )} */}
              </Select>
            </Form.Item>
            <Form.Item name="dateRange" className="col col-12 col-md-3">
              <DatePicker.RangePicker
                allowEmpty={[true, true]}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              name="unread"
              valuePropName="checked"
              className="col col-12 col-md-2 align-self-center"
            >
              <Checkbox className="mt-2">{t("project.markAsUnread")}</Checkbox>
            </Form.Item>
            <Form.Item className="col col-auto">
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                {t("search")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}

      <Table
        className="project-table mt-3"
        columns={
          auth.user.role === UserRole.SELLER && !isSelf
            ? sellerColumns
            : columns
        }
        showSorterTooltip={false}
        rowKey={(record) => `project-${record.id}`}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        rowClassName={(record: Project, index) => {
          let c = "";
          if (
            record.status !== ProjectStatus.IN_PROGRESS &&
            (auth.user.role === UserRole.BUYER
              ? !record.readByBuyer
              : !record.readBySeller)
          ) {
            c = "fw-bold bg-row-unread";
          }
          return c;
        }}
        expandable={{
          columnTitle: t("parts"),
          expandIcon:
            auth.user.role === UserRole.BUYER || isSelf
              ? ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <UpCircleOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <DownCircleOutlined onClick={(e) => onExpand(record, e)} />
                  )
              : undefined,
          expandedRowRender:
            auth.user.role === UserRole.BUYER || isSelf
              ? (record) => (
                  <div className="part-list">
                    <Swiper
                      slidesPerView={4}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                    >
                      {record.parts?.length === 0 && (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                      {record.parts?.map((item: Part) => (
                        <SwiperSlide key={`part-${item.id}`}>
                          <Card className="p-0">
                            <PartImage
                              src={
                                item?.preview2dUrl ||
                                item?.dxfFileUrl ||
                                item?.previewPngUrl
                              }
                              part={item}
                              width={"100%"}
                              height={150}
                              wrapperStyle={{
                                fontSize: 50,
                              }}
                            />
                            <div className="mt-3 mb-2 part-name fw-bold">
                              {item.partFileName}
                            </div>
                            <div className="d-flex">
                              <label>
                              {item.material?.name || "--"}
                                /{item.material?.number || "--"}
                              </label>
                              <div className="ms-auto fw-bold">
                                <CurrencyFormat
                                  value={
                                    item?.auto ||
                                    auth.user?.role === UserRole.SELLER
                                      ? item?.unitPrice * item?.quantity
                                      : undefined
                                  }
                                  adaptPrice={
                                    item?.adaptedUnitPrice != null
                                      ? item.adaptedUnitPrice * item.quantity
                                      : undefined
                                  }
                                  isHideAdapt={
                                    (record.selfCalculation &&
                                      (!auth.isLogined ||
                                        auth.user?.role === UserRole.BUYER)) ||
                                    (!record.selfCalculation && record.manual)
                                  }
                                  hiddenValue={
                                    (item?.auto && item?.unitPrice == null) ||
                                    (auth.user?.role === UserRole.BUYER &&
                                      !item?.auto &&
                                      (record?.status ===
                                        ProjectStatus.IN_PROGRESS ||
                                        record?.status ===
                                          ProjectStatus.REQUESTED_OFFER))
                                  }
                                />
                              </div>
                            </div>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )
              : undefined,
        }}
      />
      <Modal
        open={isOpenDeleteProject}
        title={t("popup.deleteOffer.title")}
        onCancel={setProjectId.bind(null, undefined)}
        onOk={performDeleteAction.bind(null, projectId)}
        okButtonProps={{
          loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
      >
        <p>{t("popup.deleteOffer.message")}</p>
      </Modal>
    </div>
  );
}

export default ListProjects;
