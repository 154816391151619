/* eslint-disable jsx-a11y/scope */
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Format/CurrencyFormat";

interface DefaultProps {
  data?: any;
  layout?: "light" | "dark";
  displayKeys?: any[];
  onQuantitySelected?: any;
}

function PriceReference(props: DefaultProps) {
  const { data, layout = "dark", displayKeys, onQuantitySelected } = props;
  const { t } = useTranslation();
  const onSelect = (key: any) => {
    if (onQuantitySelected) onQuantitySelected(key);
  };
  return (
    <table className={`table table-price-reference mb-0 ${layout === "dark" ? "text-white" : ""}`}>
      <thead>
        <tr>
          <th scope="col">{t("quantity")}</th>
          <th scope="col">{t("price.unitPrice")}</th>
        </tr>
      </thead>
      <tbody>
        {!!data &&
          Object.keys(data).map((key: any, index) =>
            !displayKeys || displayKeys.indexOf(key) > -1 ? (
              <tr
                key={`referenceCosts-${index}`}
                onClick={onSelect.bind(null, key)}
              >
                <td scope="col">{key}</td>
                <td scope="col" className="text-end">
                  <CurrencyFormat value={data[key]} />
                </td>
              </tr>
            ) : (
              <></>
            )
          )}
      </tbody>
    </table>
  );
}

export default PriceReference;
